import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';

import Breadcrumbs from '../components/breadcrumbs';
import Layout from '../components/layout';

const Privacy = ({ data }) => {
  return (
    <Layout>
      <main id="main">
      <Breadcrumbs title="Privacy" />
        <section>
            <BlockContent blocks={data.privacy.content} className="container" />
        </section>
      </main>
    </Layout>
  );
}

export const query = graphql`
  {
    privacy: sanityPrivacyContent {
      content: _rawContent
    }
  }
`

export default Privacy;

